.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  background-color: #eee;
}

.buttons {
  display: grid;
  grid-template-columns: 170px 1fr 70px;
}

.codes {
  height: 300px;
  overflow-y: auto;
  scrollbar-color: #102A43 #829AB1;
  scrollbar-width: thin;
}

.code {
  font-size: 11px;
  word-break: break-all;
  padding-top: 3px;
  padding-left: 30px;
  /* text-align: center; */
}

.error {
  height: 300px;
}

.errorText {
  font-size: 16px;
  color: red;
  text-align: center;
}

.tovars {
  height: 300px;
  overflow-y: auto;
  scrollbar-color: #102A43 #829AB1;
  scrollbar-width: thin;
}

.codes::-webkit-scrollbar, .tovars::-webkit-scrollbar {
  width: 3px;
}

.codes::-webkit-scrollbar-track, .tovars::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.codes::-webkit-scrollbar-thumb, .tovars::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.tovar {
  display: grid;
  gap: 5px;
  grid-template-columns: 2fr 1fr;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #455c16;
}

.tovarName {
  font-weight: bold;
  padding: 5px;
}

.tovarParty {
  font-style: italic;
  text-align: center;
}

.tovarQuantity {
  color: #003d00;
  text-align: end;
}

.tovarScaned {
  color: red;
  font-weight: bold;
  text-align: center;
}

.tovarScanedEqual {
  color: #003d00;
  text-align: center;
}